import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown, FiShoppingCart, FiSearch, FiChevronRight } from "react-icons/fi";
import logo from "../../assets/images/logo.png";
import HeaderBar from "../common/HeaderBar";
import { ProductConsumer } from '../../productcontext'
import $ from 'jquery'
import MenuCart from "./menucart/MenuCart";


export default function Navbar() {
    const [searchOpen, setsearchOpen] = useState(false)
    const [navOpen, setnavOpen] = useState(false)

    /*====  side-widget-menu  =====*/
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item.active', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass('active');
    })

    // $(window).on('scroll', function () {

    //     // Header Menu Fixed
    //     if ($(window).scrollTop() > 30) {
    //         $('body').addClass('header-menu-fixed');
    //         $('.header-menu-wrapper').addClass('d-none');
    //         $('.scroll-d-block').addClass('d-block').removeClass('d-none');
    //     } else {
    //         $('body').removeClass('header-menu-fixed');
    //         $('.header-menu-wrapper').removeClass('d-none');
    //         $('.scroll-d-block').removeClass('d-block').addClass('d-none');
    //     }
    // });

    return (
        <>
            <HeaderBar />

            <div className="header-menu-wrapper">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <Link to="/">
                                <img src={logo} alt="Logo" style={{width: '40%'}} />
                            </Link>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    <li>
                                        {/* <Link to="/">
                                            Home <FiChevronDown className="menu-icon" />
                                        </Link> */}
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/">Home v1</Link>
                                            </li>
                                            <li>
                                                <Link to="/home-v2">Home v2</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {/* <Link to="/about">
                                            Pages <FiChevronDown className="menu-icon" />
                                        </Link> */}
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/about">About</Link>
                                            </li>
                                            <li>
                                                <Link to="/team-grid">Team <FiChevronRight className="icon" /></Link>
                                                <ul className="dropdown-menu-item">
                                                    <li><Link to="/team-grid">Team Grid</Link></li>
                                                    <li><Link to="/team-details">Team Details</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/testimonial">Testimonial</Link>
                                            </li>
                                            <li>
                                                <Link to="/faq">FAQ</Link>
                                            </li>
                                            <li>
                                                <Link to="/our-pricing">Our Pricing</Link>
                                            </li>
                                            <li>
                                                <Link to="/error">404</Link>
                                            </li>
                                            <li>
                                                <Link to="/sign-up">User <FiChevronRight className="icon" /></Link>
                                                <ul className="dropdown-menu-item">
                                                    <li><Link to="/sign-up">Sign Up</Link></li>
                                                    <li><Link to="/login">Login</Link></li>
                                                    <li><Link to="/forgot-password">Recover Password</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {/* <Link to="/services">
                                            Services <FiChevronDown className="menu-icon" />
                                        </Link> */}
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/services">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-details">Service Details</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {/* <Link to="/portfolio-grid">
                                            Portfolio <FiChevronDown className="menu-icon" />
                                        </Link> */}
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/portfolio-grid">Portfolio Grid</Link>
                                            </li>
                                            <li>
                                                <Link to="/portfolio-details">Portfolio Details</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {/* <Link to="/blog-full-width">
                                            Blog <FiChevronDown className="menu-icon" />
                                        </Link> */}
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/blog-full-width">Blog Full Width</Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-grid">Blog Grid</Link>
                                            </li>
                                            <li>
                                                <Link to="/blog-details">Blog Details</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {/* <Link to="/shop-home">
                                            Shop <FiChevronDown className="menu-icon" />
                                        </Link> */}
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/shop-home">Shop Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/shop">Shop Sidebar</Link>
                                            </li>
                                            <li>
                                                <Link to="/product-details">Product Details</Link>
                                            </li>
                                            <li>
                                                <Link to="/cart">Cart</Link>
                                            </li>
                                            <li>
                                                <Link to="/checkout">Checkout</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* <li>
                                        <Link to="/contact">Contact</Link>
                                    </li> */}
                                </ul>
                            </nav>
                            <div className="header-menu-right-flex">
                                <div className="header-menu-cart">
                                    <ul>
                                        <li>
                                            <div className="cart__icon">
                                                {/* <Link to="#">
                                                    <FiShoppingCart />
                                                </Link> */}
                                                {/* <span className="cart__item-count">
                                                    <ProductConsumer>
                                                        {(value) => {
                                                            const { cart } = value;
                                                            if (cart.length > 0) {
                                                                return (
                                                                    <span>
                                                                        {cart.length}
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    "0"
                                                                );
                                                            }
                                                        }}
                                                    </ProductConsumer>
                                                </span> */}
                                            </div>

                                            <MenuCart />
                                        </li>
                                        <li>
                                            <Link to="#" className="search-button" onClick={() => setsearchOpen(!searchOpen)}>
                                                {/* <FiSearch className="search-icon" /> */}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="side-menu-open" onClick={() => setnavOpen(!navOpen)}>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-menu-wrapper d-none scroll-d-block">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <a href="/">
                                <img src={logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    <li>
                                        <a href="/">
                                            Home <FiChevronDown className="menu-icon" />
                                        </a>
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <a href="/">Home v1</a>
                                            </li>
                                            <li>
                                                <a href="/home-v2">Home v2</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/about">
                                            Pages <FiChevronDown className="menu-icon" />
                                        </a>
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <a href="/about">About</a>
                                            </li>
                                            <li>
                                                <a href="/team-grid">Team <FiChevronRight className="icon" /></a>
                                                <ul className="dropdown-menu-item">
                                                    <li><a href="/team-grid">Team Grid</a></li>
                                                    <li><a href="/team-details">Team Details</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="/testimonial">Testimonial</a>
                                            </li>
                                            <li>
                                                <a href="/faq">FAQ</a>
                                            </li>
                                            <li>
                                                <a href="/our-pricing">Our Pricing</a>
                                            </li>
                                            <li>
                                                <a href="/error">404</a>
                                            </li>
                                            <li>
                                                <a href="/sign-up">User <FiChevronRight className="icon" /></a>
                                                <ul className="dropdown-menu-item">
                                                    <li><a href="/sign-up">Sign Up</a></li>
                                                    <li><a href="/login">Login</a></li>
                                                    <li><a href="/forgot-password">Recover Password</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/services">
                                            Services <FiChevronDown className="menu-icon" />
                                        </a>
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <a href="/services">Services</a>
                                            </li>
                                            <li>
                                                <a href="/service-details">Service Details</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/portfolio-grid">
                                            Portfolio <FiChevronDown className="menu-icon" />
                                        </a>
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <a href="/portfolio-grid">Portfolio Grid</a>
                                            </li>
                                            <li>
                                                <a href="/portfolio-details">Portfolio Details</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/blog-full-width">
                                            Blog <FiChevronDown className="menu-icon" />
                                        </a>
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <a href="/blog-full-width">Blog Full Width</a>
                                            </li>
                                            <li>
                                                <a href="/blog-grid">Blog Grid</a>
                                            </li>
                                            <li>
                                                <a href="/blog-details">Blog Details</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/shop-home">
                                            Shop <FiChevronDown className="menu-icon" />
                                        </Link>
                                        <ul className="dropdown-menu-item">
                                            <li>
                                                <Link to="/shop-home">Shop Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/shop">Shop Sidebar</Link>
                                            </li>
                                            <li>
                                                <Link to="/product-details">Product Details</Link>
                                            </li>
                                            <li>
                                                <Link to="/cart">Cart</Link>
                                            </li>
                                            <li>
                                                <Link to="/checkout">Checkout</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="header-menu-right-flex">
                                <div className="header-menu-cart">
                                    <ul>
                                        <li>
                                            <div className="cart__icon">
                                                <Link to="#">
                                                    <FiShoppingCart />
                                                </Link>
                                                {/* <span className="cart__item-count">
                                                    <ProductConsumer>
                                                        {(value) => {
                                                            const { cart } = value;
                                                            if (cart.length > 0) {
                                                                return (
                                                                    <span>
                                                                        {cart.length}
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    "0"
                                                                );
                                                            }
                                                        }}
                                                    </ProductConsumer>
                                                </span> */}
                                            </div>

                                            <MenuCart />
                                        </li>
                                        <li>
                                            <Link to="#" className="search-button" onClick={() => setsearchOpen(!searchOpen)}>
                                                {/* <FiSearch className="search-icon" /> */}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="side-menu-open" onClick={() => setnavOpen(!navOpen)}>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setnavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li className="sidenav__item">
                            <Link to="/">home</Link>
                            <span className="menu-plus-icon"></span>
                            <ul className="side-sub-menu">
                                <li><Link to="/">Home one</Link></li>
                                <li><Link to="/home-v2">Home two</Link></li>
                            </ul>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/about">pages</Link>
                            <span className="menu-plus-icon"></span>
                            <ul className="side-sub-menu">
                                <li><Link to="/about">about us</Link></li>
                                <li><Link to="/team-grid">team grid</Link></li>
                                <li><Link to="/team-details">team detail</Link></li>
                                <li><Link to="/testimonial">testimonial</Link></li>
                                <li><Link to="/faq">FAQS</Link></li>
                                <li><Link to="/our-pricing">pricing</Link></li>
                                <li><Link to="/error">404 error page</Link></li>
                                <li><Link to="/sign-up">sign up</Link></li>
                                <li><Link to="/login">login</Link></li>
                                <li><Link to="/forgot-password">recover password</Link></li>
                            </ul>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/services">services</Link>
                            <span className="menu-plus-icon"></span>
                            <ul className="side-sub-menu">
                                <li><Link to="/services">services</Link></li>
                                <li><Link to="/service-details">service detail</Link></li>
                            </ul>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/portfolio-grid">portfolio</Link>
                            <span className="menu-plus-icon"></span>
                            <ul className="side-sub-menu">
                                <li><Link to="/portfolio-grid">portfolio grid</Link></li>
                                <li><Link to="/portfolio-details">portfolio detail</Link></li>
                            </ul>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/blog-full-width">blog</Link>
                            <span className="menu-plus-icon"></span>
                            <ul className="side-sub-menu">
                                <li><Link to="/blog-full-width">blog full width</Link></li>
                                <li><Link to="/blog-grid">blog grid</Link></li>
                                <li><Link to="/blog-details">blog detail</Link></li>
                            </ul>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/shop-home">shop</Link>
                            <span className="menu-plus-icon"></span>
                            <ul className="side-sub-menu">
                                <li><Link to="/shop-home">shop home</Link></li>
                                <li><Link to="/shop">shop sidebar</Link></li>
                                <li><Link to="/product-details">product details</Link></li>
                                <li><Link to="/cart">cart</Link></li>
                                <li><Link to="/checkout">checkout</Link></li>
                            </ul>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/contact">contact</Link>
                        </li>
                        <li className="sidenav__item sidenav__item2 text-center">
                            <Link to="/sign-up">sign up</Link>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    );
}



