import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { FiTarget, FiMonitor } from "react-icons/fi";
import { DiAtom } from "react-icons/di";
import { ImConnection } from "react-icons/im";
import { AiOutlineDollar, AiOutlineFileSearch } from "react-icons/ai";
import { IoIosBuild } from "react-icons/io";


export default class Service extends Component {
    servicestates = {
        items: [
            {
                icon: <FiTarget className="service-items-icon" />,
                title: 'Professional Service.',
                description: 'We plan, design and install the highest standard of structured cabling systems, including data, voice, multimedia, VoIP and other specialty requirements.'
            },
            {
                icon: <ImConnection className="service-items-icon" />,
                title: 'Wireless Solutions.',
                description: 'Issues with hard to run cabling will never be a problem, we also offer wireless solutions to keep your home and business connected.'
            },
            {
                icon: <DiAtom className="service-items-icon" />,
                title: 'Stellar Performance.',
                description: 'Let us assist you in setting priorities to better serve your long term goals. We have a proven reputation for high quality, efficient and innovative installations.'
            },
            // {
            //     icon: <AiOutlineFileSearch className="service-items-icon" />,
            //     title: 'Market Research.',
            //     description: 'sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.'
            // },
            // {
            //     icon: <IoIosBuild className="service-items-icon" />,
            //     title: 'Strong Business',
            //     description: 'sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.'
            // },
            // {
            //     icon: <FiMonitor className="service-items-icon" />,
            //     title: 'Data Center',
            //     description: 'sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.'
            // }
        ]
    }
    render() {
        return (
            <section className="services-area">
                <div className="container">
                    <div className="services-items-grid">
                        {
                            this.servicestates.items.map((item, index) => {
                                return <div className="service-items" key={index}>
                                    {/* <span className="service-items-num">{index}</span> */}
                                    <span className="service-items-iconbox">{item.icon}</span>
                                    <Link to="/service-details">
                                        <h3 className="service-items-title">{item.title}</h3>
                                    </Link>
                                    <p className="service-items-description">{item.description}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

