import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SectionsTitle from '../common/SectionsTitle'
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import img1 from '../../assets/images/about-img4.jpg'
import img2 from '../../assets/images/about-img4.jpg'
import img3 from '../../assets/images/about-img4.jpg'
import expert_team from '../../assets/images/Teamwork.jpg'
import industry_knowledge from '../../assets/images/pexels-field-engineer-442150.jpg'
import cost_effective from '../../assets/images/cost_effective_symbol44427027_M.jpg'

export default class SimpleTab extends Component {
    tabstate = {
        tabs: [
            {
                img: expert_team,
                desc: "One of the things that makes G&G connections so great is our mission to understand and build teams who will collectively take on projects, and provide excellent rapport with our customer base.",
                button: "Get Started"
            },
            {
                img: industry_knowledge,
                desc: "We specialize in Data Center solutions, office building environments, new construction and tenant improvement. ",
                button: "Get Started",
                img_position: "img_right"
            },
            {
                img: cost_effective,
                desc: "With so many demands to have access to data through structured cabling, the G&G Connections team is ready to offer installs that will not only last you in the long run, but will be budget friendly while living up to current trends in the market. ",
                button: "Get Started"
            }
        ]
    }

    tabsnav = {
        navs: [
            {
                title: "Expert Team"
            },
            {
                title: "Industry Knowledge"
            },
            {
                title: "Cost Effective Solutions"
            }
        ]
    }
    render() {
        return (
            <section className="tabs-wrapper">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <SectionsTitle title="Why Choose Us." subtitle="" />
                        </div>
                    </div>


                    <div className="tabs-content-wrap">
                        <Tabs>
                            <TabList className="tabs-nav">
                                {this.tabsnav.navs.map((item, index) => {
                                    return <Tab key={index}>{item.title}</Tab>
                                })}
                            </TabList>
                            {this.tabstate.tabs.map((tab, index) => {
                                return <TabPanel className="tabs-content" key={index}>
                                    <div className={'tabs-content-flex ' + tab.img_position}>
                                        <div className="tabs-left">
                                            <img src={tab.img} alt="Tabs" width='555' />
                                        </div>
                                        <div className="tabs-right">
                                            <p className="tabs-desc">
                                                {tab.desc}
                                            </p>
                                            <Link to="/#" className="theme-button">{tab.button} <FiChevronRight className="icon" /></Link>
                                        </div>
                                    </div>
                                </TabPanel>
                            })}
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}
