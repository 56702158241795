
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay } from 'react-icons/fi'
import about_video_img from '../../assets/images/img1.jpg'
import group_photo from '../../assets/images/Screen-Shot-2020-04-23-at-4.12.08-PM.png'
import ethernet_switch from '../../assets/images/pexels-brett-sayles-2881224.jpg'
import history_img from '../../assets/images/img2.jpg'
import ModalVideo from 'react-modal-video'

export default class About1 extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal2 = this.openModal2.bind(this)
    }

    openModal2() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <section className="about-st1-wrapper">
                <div className="container">
                    <div className="about-st1-grid">
                        <div className="about-left">
                            <h1 className="about-left-title">
                                We Are Trusted by More Than <strong>120 Clients.</strong>
                            </h1>
                            <p className="about-left-desc">
                            Whether you’re a new residential construction, small business, medium sized company or a large corporation, we have a solution to suit your business. Our team works to guarantee installations on time and within budget.
                            </p>
                            {/* <Link to="/#" className="theme-button">
                                Join Now <FiChevronRight className="icon" />
                            </Link> */}
                        </div>
                        <div className="about-right">
                            <img className="about-right-img" src={group_photo} alt="About Video" />
                            {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='t3tsMEhlvwM' onClose={() => this.setState({ isOpen: false })} /> */}
                            {/* <div className="video-button-box">
                                <Link to="/#" className="video-button" onClick={this.openModal2}>
                                    <span className="icon"><FiPlay /></span>
                                    Watch Video
                                </Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="history-area">
                        <div className="row">
                            <div className="col-8">
                                <img className="history_img" src={ethernet_switch} alt="History" />
                            </div>
                            <div className="history-details-box">
                                <h1 className="history-details-title">
                                    More Than <strong>130 Projects </strong>
                                    Were Completed.
                                </h1>
                                <p className="history-details-desc">
                                When it comes to this business, G&G connections repeatedly provides high quality solutions for Businesses and Homes. Our team is headed by leaders with over 15 years of industry knowledge.
                                </p>
                                {/* <Link to="/#" className="theme-button">
                                    Get Started <FiChevronRight className="icon" />
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
