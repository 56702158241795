import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.default.min.css";
import { FiChevronRight } from "react-icons/fi";

export default class owlcarousel extends Component {
   sliders = {
      items: [
         {
            title: "We Help People to Run Successful Business.",
            description:
               "G&G Connections take pride in our work. Our professionally trained and accredited team never settle for mediocrity. We’ll be there come rain or shine, helping you meet deadlines",
            button1: "Get Started",
            button2: "Learn More",
            bg: "bg-1",
            col: "8",
         },
         {
            title: "We Take Extra Care of Your Business.",
            description:
               "The staff is knowledgeable, commited, and proud of the work we do. Our management team and production workers all strive to maintain the success of our customers.",
            button1: "Let's Start Now",
            button2: "Learn More",
            bg: "bg-3",
            col: "8 offset-2 text-center",
         },
         {
            title: "Smart and effective Solutions for Your Home.",
            description:
               "Let us take care of your home needs, we specialize in CCTV systems & data infrastructure needs inside your data driven residence.",
            button1: "Let's Start Now",
            button2: "Learn More",
            bg: "bg-2",
            col: "8",
         },
      ],
   };
   render() {
      return (
         <section className="hero-slider-wrapper">
            <OwlCarousel
               className="owl-theme"
               autoplaySpeed={"10"}
               loop={true}
               autoplay={true}
               margin={10}
               dots={false}
               nav
               items={1}
            >
               {this.sliders.items.map((item, index) => {
                  return (
                     <div key={index} className={"hero-slider-item " + item.bg}>
                        <div className="container">
                           <div className="row">
                              <div className={"col-" + item.col}>
                                 <div className="hero-slider-content">
                                    <h1>{item.title}</h1>
                                    <p>{item.description}</p>
                                    <div className="hero-slider-btns">
                                       {/* <Link to="/#" className="theme-button">
                                          {item.button1}{" "}
                                          <FiChevronRight className="icon" />
                                       </Link>
                                       <Link to="/#" className="theme-button">
                                          {item.button2}{" "}
                                          <FiChevronRight className="icon" />
                                       </Link> */}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </OwlCarousel>
         </section>
      );
   }
}
